import self from "../img/self.png"
import mock1 from "../img/mock1.png"
import mock2 from "../img/mock2.png"
import mock3 from "../img/mock3.png"
import mock4 from "../img/mock4.png"
import mock5 from "../img/mock5.png"
import SDPN from "../img/san-diego-pride-network.gif"
import CrookedCredenza from "../img/crooked-credenza.gif"
import ChickleChat from "../img/chickle-chat.gif"
import GraceKlassen from "../img/grace-klassen.png"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    firstName: "Shaun",
    lastName: "Stephenson",
    initials: "sfs", // the example uses first and last, but feel free to use three or more if you like.
    position: "a Full Stack Web Developer",
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '☕️',
            text: 'fueled by espresso'
        },
        {
            emoji: '☀️',
            text: 'based in sunny socal'
        },
        {
            emoji: "🗺️",
            text: "freelance full stack developer"
        },
        {
            emoji: "📧",
            text: "shaun@shaunfitzgarald.com"
        }
    ],
    socials: [
        {
            link: "https://github.com/shaunfitzgarald",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://linkedin.com/in/shaunfitzgarald",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },
        {
            link: "https://docs.google.com/document/d/e/2PACX-1vSu3iLV4A-zvOIZtq3ymnkx3uq551UR3oVWr1bZtpUKA3uRMXP-czndZ4l53vZG715b4Dk9umo-k6sx/pub",
            icon: 'fa fa-file-text-o',
            label: 'resume'
        },
        {
            link: "https://instagram.com/shaunfitzgarald",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://twitter.com/shaunfitzgarald",
            icon: "fa fa-twitter",
            label: 'twitter'
        },
// Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
// Just change the links so that they lead to your social profiles.

    ],
    bio: "Hi there! 👋 I’m Shaun, a full-stack web developer based in San Diego, California. I enjoy creating web applications that are functional, user-friendly, and impactful. Outside of work, I’m always up for learning something new, exploring music, or finding inspiration in the world around me.",
    skills:
        {
            proficientWith: [
                'ruby', 
                'ruby on rails', 
                'javascript', 
                'react', 
                'firebase', 
                'git', 
                'github', 
                'bootstrap', 
                'react-strap', 
                'html5', 
                'css', 
                'npm', 
                'webpack', 
                'postgresql', 
                'tailwindcss', 
                'google apis', 
                'haml', 
                'aos', 
                'chatgpt', 
                'claude', 
                'vertex ai', 
                'google gemini'
            ],
            exposedTo: [
                'nodejs', 
                'swift', 
                'css3', 
                'react native', 
                'figma', 
                'wordpress', 
                'webpack', 
                'mongodb', 
                'aws', 
                'nextjs', 
                'docker', 
                'graphql', 
                'typescript'
            ]
        }
    ,
    hobbies: [
        {
            label: 'music',
            emoji: '🎧',
            description: 'Playing instruments and exploring new genres keeps me inspired.'
        },
        {
            label: 'dancing',
            emoji: '💃',
            description: 'I enjoy going out dancing with friends and letting loose on the dance floor.'
        },
        {
            label: 'movies',
            emoji: '🎥',
            description: 'I love diving into stories and analyzing films across all genres.'
        },
        {
            label: 'cooking',
            emoji: '🌶',
            description: 'Experimenting with recipes and exploring international cuisines.'
        },
        {
            label: 'travel',
            emoji: '✈️',
            description: 'Discovering new places, cultures, and hidden gems inspires me.'
        },
        {
            label: 'beach',
            emoji: '🏖',
            description: 'Relaxing by the water, swimming, or enjoying beach sunsets is my favorite way to unwind.'
        },
        {
            label: 'writing',
            emoji: '🖊️',
            description: 'I enjoy journaling as a way to reflect, organize my thoughts, and stay grounded.'
        }
    ],
    portfolio: [ 
        {
            title: "PosturePortal: a chiropractic CRM",
            live: "posture-portal.com",
            source: "https://github.com/shaunfitzgarald/chiropractic-onboarding.git",
            video: "https://youtube.com/embed/R5qmO6eQGaE"
        },
        {
            title: "ChickleChat: a social network",
            live: "https://chickle-chat.onrender.com",
            source: "https://github.com/shaunfitzgarald/chickle-chat.git",
            image: ChickleChat
        },
        {
            title: "Grace Klassen Marketing Solutions: a site for a marketing agency",
            live: "graceklassen.com",
            source: "https://github.com/shaunfitzgarald/grace-klassen-react.git",
            image: GraceKlassen
        },
        {
            title: "The Crooked Credenza: a site for an interior designer",
            live: "https://the-crooked-credenza.onrender.com",
            source: "https://github.com/shaunfitzgarald/the-crooked-credenza.git",
            image: CrookedCredenza
        },
        {
            title: "San Diego Pride Network: an events platform",
            live: "https://sand-diego-pride-network.onrender.com",
            source: "https://github.com/shaunfitzgarald/san-diego-pride-network.git",
            image: SDPN
        },
        {
            title: "San Diego Startup Map: an app to make startups more visible",
            live: "https://sandiegostartupmap.com",
            image: mock4
        },
        {
            title: "BrickByBrick: a site for lego enthusiasts",
            live: "https://brick-by-brick-frontend.onrender.com",
            source: "https://github.com/byteSizeFox/brick-by-brick-frontend",
            image: mock3
        },
        // {
        //     title: "Pig Latin Translator: a web app to translate english into pig latin",
        //     live: "https://shaunfitzgarald.github.io/pig-latin-translator/",
        //     source: "https://github.com/shaunfitzgarald/pig-latin-translator.git",
        //     image: mock2
        // },
        // {
        //     title: "Tic Tac Toe: a web app to play tic tac toe",
        //     live: "https://shaunfitzgarald.github.io/tic-tac-toe-app/", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
        //     source: "https://github.com/shaunfitzgarald/tic-tac-toe-app.git", // this should be a link to the **repository** of the project, where the code is hosted.
        //     image: mock1
        // }
    ]
}