import React from 'react';
import IconLink from "./IconLink";
import {Box} from "@mui/material";

function PortfolioBlock(props) {
   const {image, video, live, source, title} = props;
   return (
       <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} width="100%">
           {video ? (
               <Box position="relative" width="100%" paddingTop="56.25%"> {/* 16:9 Aspect Ratio */}
                   <Box component={'iframe'} src={video} position="absolute" top="0" left="0" width="100%" height="100%" frameBorder="0" allowFullScreen />
               </Box>
           ) : (
               <Box component={'img'} src={image} alt={'mockup'} />
           )}
           <h1 style={{fontSize: '2rem', marginTop: '1rem'}}>{title}</h1>
           <Box className={'portfolio'} display={'flex'} flexDirection={'column'} gap={'0.5rem'}
                alignItems={'center'} fontSize={'1.5rem'} py={'2rem'} mt={'1rem'}>
               <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
                   <IconLink link={live} title={'Live Demo'} icon={'fa fa-safari'} />
               </Box>
               <Box p={1} border={'2px solid black'} borderRadius={'25px'}>
                   <IconLink link={source} title={'Source Code'} icon={'fa fa-code'} />
               </Box>
           </Box>
       </Box>
   );
}

export default PortfolioBlock;